import { node } from "prop-types";
import { createContext, useContext, useMemo, useState } from "react";

/**
 * @typedef {object} QuizProfileContext
 * @property {string} color - The current quiz color value
 * @property {string} supportColor - The current quiz support color value
 * @property {string} backgroundColor - The current quiz background color value
 * @property {import('react').Dispatch<string>} setColor - function to update the color value
 * @property {import('react').Dispatch<string>} setSupportColor - function to reset both color and support color values to their default values
 * @property {import('react').Dispatch<string>} setBackgroundColor - function to update the background color value
 * @property {() => void} resetColors
 */
const QuizProfileContext = createContext({
  color: "",
  supportColor: "",
  backgroundColor: "",
  // eslint-disable-next-line
  setColor: (_value) => null,
  // eslint-disable-next-line
  setSupportColor: (_value) => null,
  // eslint-disable-next-line
  setBackgroundColor: (_value) => null,
  // eslint-disable-next-line
  resetColors: () => null,
});

/**
 * Provider component for quiz color context
 *
 * @component
 * @param {object} props
 * @param {import('react').ReactNode} props.children - Child components that will have access to the context
 * @returns {import("react").ReactNode}
 */
export const QuizProfileColorsProvider = ({ children }) => {
  const [color, setColor] = useState(null);
  const [supportColor, setSupportColor] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState(null);

  const value = useMemo(() => {
    const resetColors = () => {
      setColor(null);
      setSupportColor(null);
      setBackgroundColor(null);
    };
    return {
      color,
      supportColor,
      backgroundColor,
      setColor,
      setSupportColor,
      setBackgroundColor,
      resetColors,
    };
  }, [color, supportColor, backgroundColor]);

  return (
    <QuizProfileContext.Provider value={value}>
      {children}
    </QuizProfileContext.Provider>
  );
};

QuizProfileColorsProvider.propTypes = {
  children: node.isRequired,
};

/**
 * Hook to access the quiz background context
 *
 * @returns {QuizProfileContext}
 * @throws {Error} If used outside of QuizProfileColorsProvider
 * @requires QuizProfileColorsProvider - Must be used within a QuizProfileColorsProvider component
 *
 * @example
 * // Basic usage
 * const { color, setColor, supportColor, setSupportColor, backgroundColor, setBackgroundColor, resetColors } = useQuizColors();
 *
 * // Setting new colors
 * setColor('blue');
 * setSupportColor('green');
 * setBackgroundColor('white');
 *
 * // Using in a component
 * function QuizComponent() {
 *   const {
 *     color,
 *     setColor,
 *     supportColor,
 *     setSupportColor,
 *     backgroundColor,
 *     setBackgroundColor
 *   } = useQuizColors();
 *
 *   useEffect(() => {
 *     // Set colors when component mounts
 *     setColor('red');
 *     setSupportColor('green');
 *     setBackgroundColor('white');
 *
 *     // Clean up when component unmounts
 *     return () => {
 *       setColor(null);
 *       setSupportColor(null);
 *       setBackgroundColor(null);
 *     };
 *   }, [setColor, setSupportColor, setBackgroundColor]);
 *
 *   return (
 *     <div style={{ backgroundColor }}>
 *      <h1 style={{ color: supportColor }}>Quiz Component</h1>
 *     </div>
 *   );
 * }
 */

export const useQuizColors = () => {
  const context = useContext(QuizProfileContext);
  if (!context) {
    throw new Error(
      "useQuizColors must be used within a QuizProfileColorsProvider",
    );
  }
  return context;
};
