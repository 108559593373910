import { useCallback, useEffect } from "react";

/**
 * A hook that observes and responds to size changes of a DOM element.
 *
 * @param {React.RefObject} ref - React ref object pointing to the DOM element to observe
 * @param {function} callback - Callback function that receives a ResizeObserverEntry
 *
 * @example
 * // Basic usage to track element width
 * const MyComponent = () => {
 *   const elementRef = useRef(null);
 *
 *   useElementResizeObserver(elementRef, (entry) => {
 *     const { width } = entry.contentRect;
 *     console.log('Element width:', width);
 *   });
 *
 *   return <div ref={elementRef}>Resizable content</div>;
 * };
 *
 */

const useElementResizeObserver = (ref, callback) => {
  const handleResize = useCallback(
    (entries) => {
      if (!Array.isArray(entries) || !entries.length) {
        return;
      }

      const entry = entries[0];
      callback(entry);
    },
    [callback],
  );

  useEffect(() => {
    const element = ref.current;
    if (!element) {
      return;
    }

    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(element);

    return () => {
      resizeObserver.unobserve(element);
      resizeObserver.disconnect();
    };
  }, [ref, handleResize]);
};

export default useElementResizeObserver;
